<template>
  <div class="body">
    <!-- <div id="container"></div> -->
    <iframe class="web-view" src="//uri.amap.com/marker?poiid=B017316BC4&src=mypage&callnative=0"></iframe>
  </div>
</template>

<script>
export default {
  name: "routerHospital",
  data() {
    return {};
  },
};
</script>

<style scoped>
.body {
  font-size: 0.4rem;
}
.web-view {
  width: 100%;
  height: calc(100vh - 50px);
}
</style>